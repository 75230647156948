<template>
  <div style="background-color: #fff; padding: 16px;">
    <xz-table :columns="columns" :data="tableData" :scroll="{ x: '100%', y: 540 }" :rowKey="'id'">
        <span slot="url" slot-scope="text">
            <a :href="text" target="_blank">{{ text }}</a>
        </span>
    </xz-table>
  </div>
</template>

<script>
import xzTable from '@/components/xiaozi-ui/xz-table/src/table.vue';

export default {
  name: 'AttachmentList',
  components: {
    xzTable,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      columns: [
        {
          title: '附件名称',
          dataIndex: 'businessTypeDesc',
          key: 'businessTypeDesc',
          width: 200,
        },
        {
          title: '附件',
          dataIndex: 'url',
          key: 'url',
          scopedSlots: { customRender: 'url' },
          width: 400,
        },
      ],
    };
  },
};
</script>
