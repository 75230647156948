<template>
  <div style="background-color: #fff; padding: 16px;">
  <xz-table ref="ordersRef" :columns="columns" :data="tableData" :scroll="{ x: '100%', y: 540 }" :rowKey="'id'">
    <span slot="code" slot-scope="text, record">
      <a class="code" @click="handleView(record)">{{ text }}</a>
    </span>
    <p slot="expandedRowRender" slot-scope="record" style="margin: 0">
      <xz-table :columns="childColumns[saleOrderType]" :data="record.goodsList" :scroll="{ x: '100%' }" :rowKey="'id'"></xz-table>
    </p>
  </xz-table>
  </div>
</template>

<script>
import { saleOrderList, purchaseOrderList } from '@/api/contract';
import xzTable from '@/components/xiaozi-ui/xz-table/src/table.vue';
import * as Moment from 'dayjs';

const EXECUTION_STATUS_ARRAY_MAP = ['草稿', '审核中', '已驳回', '执行中', '已取消', '已完结'];

export default {
  name: 'SaleOrders',
  components: {
    xzTable,
  },
  props: {
    saleOrderType: {
      type: String,
      default: undefined,
    },
    contractId: {
      type: String,
      default: undefined,
    },
    saleOrderCode: {
      type: String,
      default: undefined,
    },
  },
  data () {
    return {
      columns: [
        {
          title: '订单编号',
          dataIndex: 'code',
          key: 'code',
          width: 160,
          scopedSlots: { customRender: 'code' },
          ellipsis: true,
        },
        {
          title: '订单金额',
          dataIndex: 'total',
          key: 'total',
          width: 160,
          ellipsis: true,
          customRender: (text) => {
            return text === '' || text === null ? '--' : text.toLocaleString();
          },
        },
        {
          title: '收款金额',
          dataIndex: 'collectionAmount',
          key: 'collectionAmount',
          width: 160,
          ellipsis: true,
          customRender: (text) => {
            return text === '' || text === null ? '--' : text.toLocaleString();
          },
        },
        {
          title: '开票金额',
          dataIndex: 'invoiceAmount',
          key: 'invoiceAmount',
          width: 160,
          ellipsis: true,
          customRender: (text) => {
            return text === '' || text === null ? '--' : text.toLocaleString();
          },
        },
        {
          title: '交付状态',
          dataIndex: 'receiveStatusDesc',
          key: 'receiveStatusDesc',
          width: 160,
          ellipsis: true,
        },
        {
          title: '订单状态',
          dataIndex: 'statusDesc',
          key: 'statusDesc',
          width: 160,
          ellipsis: true,
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          width: 160,
          ellipsis: true,
          customRender: (text) => {
            return text ? Moment(text * 1).format('YYYY-MM-DD HH:mm:ss') : '--';
          },
        },
      ],
      tableData: [],
      childColumns: {
        saleOrder: [
          {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            width: 160,
            ellipsis: true,
          },
          {
            title: '规格',
            dataIndex: 'specification',
            key: 'specification',
            width: 160,
            ellipsis: true,
          },
          {
            title: '型号',
            dataIndex: 'model',
            key: 'model',
            width: 160,
            ellipsis: true,
          },
          {
            title: '数量',
            dataIndex: 'num',
            key: 'num',
            width: 160,
            ellipsis: true,
          },
          {
            title: '单价',
            dataIndex: 'taxPrice',
            key: 'taxPrice',
            width: 160,
            ellipsis: true,
          },
          {
            title: '税率',
            dataIndex: 'taxRate',
            key: 'taxRate',
            width: 160,
            ellipsis: true,
            customRender: (text) => {
              return text ? parseInt(text * 100) + '%' : '--';
            },
          },
        ],
        purchaseOrder: [
          {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            width: 160,
            ellipsis: true,
          },
          {
            title: '规格',
            dataIndex: 'specification',
            key: 'specification',
            width: 160,
            ellipsis: true,
          },
          {
            title: '型号',
            dataIndex: 'model',
            key: 'model',
            width: 160,
            ellipsis: true,
          },
          {
            title: '数量',
            dataIndex: 'currentBillingNum',
            key: 'currentBillingNum',
            width: 160,
            ellipsis: true,
          },
          {
            title: '金额',
            dataIndex: 'unitPrice',
            key: 'unitPrice',
            width: 160,
            ellipsis: true,
            customRender: (text, record) => {
              return text && record.currentBillingNum ? (text * record.currentBillingNum).toLocaleString() : '';
            },
          },
          {
            title: '税率',
            dataIndex: 'taxRate',
            key: 'taxRate',
            width: 160,
            ellipsis: true,
            customRender: (text) => {
              return text ? text : '--';
            },
          },
        ],
      },
      page: {
        pageSize: 10,
        pageNum: 1,
      },
      total: 0,
    };
  },
  methods: {
    async loadData () {
      const api = this.saleOrderType === 'saleOrder' ? saleOrderList : purchaseOrderList;

      let params = {};

      if (this.saleOrderType === 'saleOrder') {
        params = {
          contractId: this.contractId,
          findOrderGoodsFlag: true,
          findInvoiceAmountFlag: true,
          findCollectionAmountFlag: true,
        };
      } else if (this.saleOrderType === 'purchaseOrder') {
        params = {
          contractId: this.contractId,
          findDeviceFlag: true,
          findGoodsFlag: true,
        };
      }

      const { body } = await api({
        ...params,
        ...this.page,
      });

      if (this.saleOrderType === 'saleOrder') {
        (body?.list || []).forEach(v => {
          v.receiveStatusDesc = v.receiveStatus === 0 ? '待收货' : v.receiveStatus === 1 ? '已收货' : '';
        });

        (body?.list || []).forEach(v => {
          v.statusDesc = EXECUTION_STATUS_ARRAY_MAP[v.status];
        });
        this.tableData = [...this.tableData, ...(body?.list || [])];
      } else if (this.saleOrderType === 'purchaseOrder') {
        const tableData = (body?.list || []).map(v => ({
          id: v.id,
          receiveStatusDesc: v.collectStateDesc || '',
          code: v.orderCode,
          statusDesc: v.orderStsDesc,
          total: v.orderTotalAmount,
          invoiceAmount: v.invoiceAmount,
          collectionAmount: v.sellerAmount,
          contractCode: v.pcCode,
          finalCustomerName: v.finalCustomerName,
          createUserName: v.createUserName,
          createTime: v.createTime,
          goodsList: v.goodsList,
        }));

        this.tableData = [...this.tableData, ...tableData];
      }
    },
    handleView (record) {
      this.$router.push({
        name: this.saleOrderType === 'saleOrder' ? 'SALESMANAGEMENT_BILLINGORDER_SALE_DETAIL' : '',
        query: {
          id: this.$encode(record.id),
        },
      });
    },
    tableScroll () {
      let target = this.$refs.ordersRef.$el.querySelector('.ant-table-body');

      const scrollHeight = target.scrollHeight - target.scrollTop;
      const clientHeight = target.clientHeight;
      if (scrollHeight === 0 && clientHeight === 0) {
        this.page.pageNum = 1;
      } else {
        if (scrollHeight < clientHeight + 5) {
          if (this.tableData.length < this.total) {
            this.page.pageNum += 1;

            this.loadData();
          }
        }
      }
    },
  },
  mounted () {
    this.$refs.ordersRef.$el
      .querySelector('.ant-table-body')
      .addEventListener('scroll', this.tableScroll, {
        passive: true,
      });

    this.loadData();
  },
  beforeDestroy () {
    this.$refs.ordersRef.$el
      .querySelector('.ant-table-body')
      .removeEventListener('scroll', this.tableScroll);
  },
};
</script>

