<template>
  <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
    <div class="a4" ref="printPaperRef" id="printPaperRef">
      <div class="page-header">
        <div>
          <img :src="require('@/assets/logo.svg')" />
        </div>
        <div>
          <span class="company">海南小紫医疗科技有限公司</span>
        </div>
      </div>
      <div class="title1">标准型维修服务合同</div>
      <table border="1" class="dashed">
        <tr>
          <td>甲方：</td>
          <td colspan="9">{{ contractData.partA.name }}</td>
        </tr>
        <tr>
          <td></td>
          <td colspan="6">地址：{{ contractData.partA.address }}</td>
          <td colspan="3">邮编：{{ contractData.partA.postCode }}</td>
        </tr>
        <tr>
          <td></td>
          <td colspan="6">法人代表：{{ contractData.partA.legalPerson || '' }}</td>
          <td colspan="3"></td>
        </tr>
        <tr>
          <td></td>
          <td colspan="6">电话：{{ contractData.partA.telephone }}</td>
          <td colspan="3"></td>
        </tr>
        <tr>
          <td></td>
          <td colspan="6">传真：{{ contractData.partA.fax }}</td>
          <td colspan="3"></td>
        </tr>
        <tr>
          <td>乙方：</td>
          <td colspan="9">{{ contractData.partB.name }}</td>
        </tr>
        <tr>
          <td></td>
          <td colspan="6">地址：{{ contractData.partB.address || '' }}</td>
          <td colspan="3">邮编：{{ contractData.partB.postCode }}</td>
        </tr>
        <tr>
          <td></td>
          <td colspan="6">法人代表：{{ contractData.partB.legalPerson || '' }}</td>
          <td colspan="3"></td>
        </tr>
        <tr>
          <td></td>
          <td colspan="6">电话：{{ contractData.partB.telephone || '' }}</td>
          <td colspan="3"></td>
        </tr>
        <tr>
          <td></td>
          <td colspan="6">传真：{{ contractData.partB.fax || '' }}</td>
          <td colspan="3"></td>
        </tr>
      </table>
      <div class="gap-top-2em indent-2em">甲乙双方经过平等协商，达成本维修服务合同（以下简称“合同”），合同内容包括具体条款、附件及合同中提及的其他文件。</div>
      <div class="gap-top-2em title2">1.&emsp;合同有效期：</div>
      <div class="indent-2em">自{{ contractData.effectiveDate[0] }}年{{ contractData.effectiveDate[1] }}月{{ contractData.effectiveDate[2] }}日 至
        {{ contractData.terminalDate[0] }}年{{ contractData.terminalDate[1] }}月{{ contractData.terminalDate[2] }}日止。</div>
      <div class="gap-top-2em title2">2.&emsp;维修服务所保设备：</div>
      <div class="indent-2em">2.1&ensp;设备类型：</div>
      <table border="1">
        <thead>
          <tr>
            <th style="width:50px;">序号</th>
            <th>设备名称</th>
            <th>设备型号</th>
            <th>生产厂家</th>
            <th>设备序列号</th>
            <th>注册证</th>
            <th>装机日期</th>
            <th>服务期限</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d, index) in contractData.deviceList" :key="`device${index}`">
            <td>{{ index + 1 }}</td>
            <td>{{ d.deviceName }}</td>
            <td>{{ d.deviceModel }}</td>
            <td>{{ d.deviceEnterpriseName }}</td>
            <td>{{ d.deviceSerialNumber }}</td>
            <td>{{ d.deviceRegisterCode }}</td>
            <td>{{ d.installationDate }}</td>
            <td>{{ d.startServiceTerm }} ~ {{ d.endServiceTerm }}</td>
          </tr>
        </tbody>
      </table>
      <div class="gap-top-1em indent-2em">2.2&ensp;附加设备系统/服务:{{ contractData.additionalDeviceSystemServe }}</div>
      <div class="indent-2em">乙方仅对以上列明的机型予以保修。</div>
      <div class="gap-top-2em title2">3.&emsp;服务类别：</div>
      <div class="indent-2em">乙方提供的维修合同服务类别为: 维修和保养服务合同</div>
      <div class="indent-2em">不包括： {{ contractData.excludeServices }}</div>
      <div class="indent-2em">3.1&ensp;响应时间：乙方应在收到甲方拨打021-52813076电话后， {{ contractData.telephoneResponse }}小时内电话响应，{{
        contractData.onsiteResponse }}小时内现场响应</div>
      <div class="indent-2em">3.2&ensp;维修时间: 周一至周五8:30至17:30，国家法定假日除外。</div>
      <div class="gap-top-2em title2">4.&emsp;乙方的责任：</div>
      <div class="indent-2em">
        <span style="position: absolute;">4.1&ensp;</span><div style="margin-left: 2.5em;display: inline-block;">定期保养:乙方每年提供{{
          contractData.yearMaintenanceTimes
        }}次定期维护,乙方工程师向甲方提供一份计划性的定期维修报告。乙方在预期保养时间之前一周内通知甲方保养时间，如因甲方原因导致不能执行保养，乙方将不对此承担责任。计划性定期的维修服务检测包括机器清洁、性能测试及校准、必要的机械或电气的检查，以及非紧急性质的补救性维修，和确保系统能按照制造商的产品规格运行的维修。此项定期维护服务间隔进行。
    </div>
    </div>
      <div class="indent-2em"><span style="position: absolute;">4.2&ensp;</span><div style="margin-left: 2.5em;display: inline-block;">乙方为甲方提供在线支持、现场检修及零备件更换。</div></div>
      <div class="indent-2em" style="display: inline-block;">
        <span style="position: absolute;">4.2.1&ensp;</span><div style="margin-left: 2.5em;display: inline-block;">在线支持，协助甲方的工程师分析和维修有关设备。电话支援在甲方拨打维修热线后提供：021-52813076维修热线，乙方资深工程师在线技术支持，答疑；In-Site(远程遥控诊断系统)连接，乙方资深工程师即时诊断机器故障，制定维修方案。</div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute;">4.2.2&ensp;</span><div style="margin-left: 2.5em;display: inline-block;">现场检修，乙方在接到甲方报修电话后派遣工程师前往维修有关设备。工程师最多不超过两个工作日到达现场。原厂认证合格的专业工程师或原厂培训的特约维修队伍将提供快速优质的现场服务。</div></div>
      <div v-if="contractData.warrantyType === 'ALL'" class="indent-2em"><span style="position: absolute;">4.2.3&ensp;</span>
        <div style="margin-left: 2.5em;display: inline-block;">零备件更换：本合同服务范围内的机器在合同有效期内进行约定维修所发生的费用（更换零部件费，乙方人工费和出差费等），由乙方承担。乙方在国内的保税仓库特定为甲方提供备件。</div></div><!--全保-->
      <div v-else class="indent-2em"><span style="position: absolute;">4.2.3&ensp;</span><div style="margin-left: 2.5em;display: inline-block;">零备件更换：本合同价款不包括零备件。如服务过程中需要更换相应零部件，由甲方提供或甲方另行承担相应费用。</div></div><!--人工保-->
      <div class="indent-2em"><span style="position: absolute;">4.2.4&ensp;</span><div style="margin-left: 2.5em;display: inline-block;">乙方提供免费系统软件升级。</div></div>
      <div class="indent-2em"><span style="position: absolute;">4.2.5&ensp;</span><div style="margin-left: 2.5em;display: inline-block;">平均开机率保证为{{ contractData.averageStartupRate }}，即每年停机不超过
        {{ contractData.yearDownMostWorkDay }}个工作日(一年253个工作日)，超过一天顺延一天；但提供备用机的情形下不视为停机。凡不在维修范围之内的系统部件损坏而导致停机，不包括在此条款之内。</div>
      </div>
      <div class="gap-top-2em title2">5.&emsp;甲方的责任：</div>
      <div class="indent-2em"><span style="position: absolute;">5.1&ensp;</span><div style="margin-left: 2.5em;display: inline-block;">甲方应按机器使用手册的要求进行操作及日常维护，并保证机器所需电源、水源的正常供应及开启和关闭以及环境的要求。</div></div>
      <div class="indent-2em"><span style="position: absolute;">5.2&ensp;</span><div style="margin-left: 2.5em;display: inline-block;">对于乙方在提供服务期间所提供的信息，甲方应该保密。乙方对该信息拥有所有权，未经乙方书面同意，甲方不得擅自使用或泄露给外界。</div></div>
      <div class="indent-2em"><span style="position: absolute;">5.3&ensp;</span><div style="margin-left: 2.5em;display: inline-block;">甲方应按合同约定的方式按时向乙方支付维修服务费用。</div></div>
      <div v-if="contractData.warrantyType !== 'ALL'" class="indent-2em"><span style="position: absolute;">5.4&ensp;</span><div style="margin-left: 2.5em;display: inline-block;">如服务过程中确需涉及零部件的调整，由此产生的费用由甲方承担。</div></div>
      <!--人工保-->
      <div class="gap-top-2em title2">6.&emsp;服务总价款：</div>
      <div class="indent-2em">
        本合同（人民币）:{{ contractData.totalAmountPerYear }}元/年&emsp;(大写): {{ contractData.chineseTotalAmountPerYear }}。
      </div>
      <div class="indent-2em">
        以上价款为含税，税率为{{ contractData.taxRate }}，如因国家相关规定导致税率产生变化，则以付款时适用的税率为准。
      </div>
      <div class="indent-2em">
        对于合同项目下涉及的零备件，如无特殊说明甲方需在更换后将原备件退还给乙方；未能归还的，甲方应当额外向乙方支付该备件采购金额的 30 % 。
      </div>
      <div class="gap-top-2em title2">7.&emsp;付款方式和销售发票：</div>
      <div class="indent-2em">7.1&ensp;经双方协商，采取以下方式付款：</div>
      <div v-if="contractData.paymentList.length === 1" class="indent-2em">合同签署后{{ contractData.paymentDays
      }}日内一次性支付全部价款。甲方应当按照合同约定将合同款项支付至交付乙方指定账户。</div>
      <template v-else>
        <div class="indent-2em">甲方按照如下约定分{{ contractData.paymentList.length || '' }}期向乙方支付合同款项：</div>
        <div v-for="(payment, index) in contractData.paymentList" class="indent-2em" :key="index">
          第{{ payment.index }}期：在{{ payment.paymentDateArray[0] }}年{{ payment.paymentDateArray[1] }}月{{
            payment.paymentDateArray[2] }}日前支付人民币{{ payment.paymentAmount
    || '' }}元；</div>
      </template>
      <div class="indent-2em">7.2&ensp;乙方指定账户信息如下：</div>
      <div class="indent-2em title2">名 称：{{ contractData.partB.name }}</div>
      <div class="indent-2em title2">账 号：{{ contractData.bankAccount }}</div>
      <div class="indent-2em title2">开户行：{{ contractData.bankName }}</div>
      <div class="indent-2em">7.3&ensp;按照如下方式开具发票：</div>
      <div class="indent-2em"> 乙方每收到甲方支付的一笔款项后向甲方开具合规有效的等值发票（发票类型为：{{ contractData.invoiceTypeName }} ）。</div>
      <div class="indent-2em"> 甲方在收到乙方开具的合规有效的等值发票后（发票类型为： {{ contractData.invoiceTypeName }}）按照双方约定向乙方支付货款。</div>
      <div class="gap-top-2em title2">8.&emsp;合同的变更和转让：</div>
      <div class="indent-2em">协议有效期内，双方不得擅自变更协议的权利和义务, 除非双方的授权代表以书面的方式确认。甲方同意且保证甲方利益的情况下，乙方可以将协议的部分或全部转让给其关联公司或第三方公司。
      </div>
      <div class="gap-top-2em title2">9.&emsp;豁免及限制：</div>
      <div class="indent-2em"><span style="position: absolute;">9.1&ensp;</span><div style="margin-left: 2.5em;display: inline-block;">本合同不包括下列服务项目(甲方如果要求乙方提供下列服务，则按照乙方“付费维修服务”计费)。</div></div>
      <div class="indent-2em"><span style="position: absolute;">9.1.1&ensp;</span><div style="margin-left: 2.5em;display: inline-block;">该机器部分拆检、翻修、重装、迁移、搬动及相应的费用及保险费用。</div></div>
      <div class="indent-2em"><span style="position: absolute;">9.1.2&ensp;</span><div style="margin-left: 2.5em;display: inline-block;">非乙方指派或甲方请第三方修理调整机器、换装零件、改装机器及配件，或其它乙方不能控制的任何原因所造成机器损坏而需乙方维护者。</div></div>
      <div class="indent-2em"><span style="position: absolute;">9.1.3&ensp;</span><div style="margin-left: 2.5em;display: inline-block;">甲方或其代表未遵照保养及操作手册上的程序所进行操作所引起的设备故障。</div></div>
      <div class="indent-2em"><span style="position: absolute;">9.1.4&ensp;</span><div style="margin-left: 2.5em;display: inline-block;">该机器配置的非乙方的外周设备，如激光相机、洗片机、高压注射器等等，经双方协商，乙方将提供有偿维修服务，具体费用，双方可另行协商。</div></div>
      <div class="gap-top-2em title2">10.&emsp;违约责任：</div>
      <div class="indent-2em"><span style="position: absolute;">10.1&ensp;</span><div style="margin-left: 2.5em;display: inline-block;">由于公认的不可抗力事件造成的损坏（如自然灾害、爆炸、房屋倒塌、暴乱、坠机及撞蓄意破坏、缺乏燃料或水电、劳资纠纷、罢工等），乙方根据实际情况部分或全部免除责任，但应及时通知甲方。</div></div>
      <div class="indent-2em"><span style="position: absolute;">10.2&ensp;</span><div style="margin-left: 2.5em;display: inline-block;">因为乙方不履行合同或者履行合同不符合约定给甲方造成损失的，乙方负责赔偿直接损失，该损失赔偿额以合同价款为限。但因不可抗力或甲方不可能控制的因素导致的情形除外。</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">10.3&ensp;</span><div style="margin-left: 2.5em;display: inline-block;">在执行维修合同期间，甲方由于非乙方的原因而不再履行本合同，除付清至终止合同日期前应付的款项，并需按照应付款项的25%向乙方承担违约责任。</div></div>
      <div class="indent-2em"><span style="position: absolute;">10.4&ensp;</span><div style="margin-left: 2.5em;display: inline-block;">在维修合同有效期内，未经乙方认可甲方擅自更换第三方零备件，乙方有权解除合同。</div></div>
      <div class="indent-2em"><span style="position: absolute;">10.5&ensp;</span><div style="margin-left: 2.5em;display: inline-block;">如果甲方超过本合同规定期限30天还未付款，乙方有权解除合同，并有权追索解除日前已到期的应付款、10.3条约定的违约金及因维权产生的其他费用（包括但不限于诉讼费、合理的律师费等）。在解除合同的前10天，乙方将书面通知甲方解除日期。</div></div>
      <div class="gap-top-2em title2">11.&emsp;反腐败条款：</div>
      <div class="indent-2em"><span style="position: absolute;">11.1&ensp;</span><div style="margin-left: 2.5em;display: inline-block;">双方应遵守国家及地方现行有效的法律法规，包括但不限于《反不正当竞争法》及国家工商行政管理局颁发的《关于禁止商业贿赂行为的暂行规定》，不得从事任何有损产品或乙方商业形象的行为，并且应遵守各项规定。</div></div>
      <div class="indent-2em"><span style="position: absolute;">11.2&ensp;</span><div style="margin-left: 2.5em;display: inline-block;">任何一方均应保留能够证明其遵守本条规定的真实准确的记录，经对方要求，应详细说明遵守本条规定的情况。</div></div>
      <div class="indent-2em"><span style="position: absolute;">11.3&ensp;</span><div style="margin-left: 2.5em;display: inline-block;">若由于任何一方违反本协议关于反商业贿赂的约定而给对方造成任何损失，包括但不限于商誉损失、政府调查、罚款等，该方应对守约方承担赔偿责任。</div></div>
      <div class="gap-top-2em title2">12.&emsp;争议解决及法律适用：</div>
      <div class="indent-2em">双方应本着友好协商的原则解决争议。如协商不成，应提交乙方主要经营地法院诉讼解决。</div>
      <div class="gap-top-2em title2">13.&emsp;本合同经双方签字盖章生效：</div>
      <div class="indent-2em">合同附件清单：（可删除没有的部分）。</div>
      <div v-if="!contractData.attachmentList.length" class="indent-2em">无</div>
      <div v-else v-for="(annex, index) in contractData.attachmentList" class="indent-2em" :key="`attach${index}`">{{ `${index +
        1}、${annex.businessTypeDesc || '/'}` }}</div>
      <div class="indent-2em">
        本合同的附件是合同的有机组成部分，具有补充、解释或修改合同的作用，对双方有约束力。
      </div>
      <table border="1" class="gap-top-2em dashed">
        <tr height="150">
          <td class="title2">甲&emsp;&emsp;方：<br />（盖章）</td>
          <td></td>
          <td class="title2">乙&emsp;&emsp;方：<br />（盖章）</td>
          <td></td>
        </tr>
        <tr height="150">
          <td class="title2">授权代表：<br />（签字）</td>
          <td></td>
          <td class="title2">授权代表：<br />（签字）</td>
          <td></td>
        </tr>
        <tr height="150">
          <td class="title2">职&emsp;&emsp;位：</td>
          <td></td>
          <td class="title2">职&emsp;&emsp;位：</td>
          <td></td>
        </tr>
        <tr height="150">
          <td class="title2">日期：</td>
          <td></td>
          <td class="title2">日期：</td>
          <td></td>
        </tr>
      </table>
    </div>
    <div style="text-align: center;">
      <a-button @click="handlePrint">打印</a-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'WarrantyContract',
  props: {
    contractData: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {};
  },
  methods: {
    handlePrint () {
      const style = '@page {margin:6mm 20mm;size: A4;} @charset "UTF-8";body {font-family: "宋体";}.a4 {width: 210mm;background-color: #fff;}.a4 .page-header {display: flex;justify-content: space-between;align-items: flex-end;font-size: 12px;padding-bottom: 6px;border-bottom: 1px solid #000;}.a4 .page-header img {width: 62%;}.a4 .page-header .company {display: inline-block;width: 16em;color: #808080;}.a4 div {line-height: 2em;}.a4 .gap-top-1em {margin-top: 1em;}.a4 .gap-top-2em {margin-top: 2em;}.a4 .title2 {font-weight: bolder;}.a4 .indent-2em {margin-left: 2em;}.a4 .title1 {text-align: center;line-height: 60px;font-size: 22px;letter-spacing: .5em;}.a4 table {width: 99%;table-layout: fixed;border-collapse: collapse;}.a4 table tr {page-break-inside: avoid;}.a4 table th {padding: 8px;font-weight: 500;}.a4 table td {word-break: break-all;padding: 8px;}.a4 table.dashed {border: 1px dashed #c8c8c8;}.a4 table.dashed td {border: 1px dashed #c8c8c8;}';

      this.$print({
        printable: 'printPaperRef',
        type: 'html',
        targetStyles: ['*'],
        style,
        scanStyles: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.a4 {
  width: 210mm;
  background-color: #fff;

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 12px;
    padding-bottom: 6px;
    border-bottom: 1px solid #000;

    img {
      width: 62%;
    }

    .company {
      display: inline-block;
      width: 16em;
      color: #808080;
    }
  }

  div {
    line-height: 2em;
  }

  .gap-top-1em {
    margin-top: 1em;
  }

  .gap-top-2em {
    margin-top: 2em;
  }

  .title2 {
    font-weight: bolder;
  }

  .indent-2em {
    margin-left: 2em;
  }

  .title1 {
    text-align: center;
    line-height: 32px;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: .5em;
  }

  table {
    width: 99%;
    table-layout: fixed;
    border-collapse: collapse;

    tr {
      page-break-inside: avoid;
    }

    th {
      padding: 8px;
      font-weight: 500;
    }

    td {
      word-break: break-all;
      padding: 8px;
    }
  }

  table.dashed {
    border: 1px dashed #c8c8c8;

    td {
      border: 1px dashed #c8c8c8;
    }
  }
}
</style>

