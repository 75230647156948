<template>
  <div class="common">
    <div class="title gap-12">
      其他条款
      <span v-if="!noAction" style="font-size: 12px; color: gray">
        <a-icon type="info-circle" style="margin-left: 4px;" />
        具体条款信息可点击下方合同预览按钮进行查看。
      </span>
      <a-alert v-if="isError" message="请完善必填条款" banner closable @close="showWarning = false" />
    </div>
    <a-form-model ref="relocationTerms" :model="clause" :rules="rules">
      <div>
        <div class="term">
          <div class="label">条款标题</div>
          <div>条款内容</div>
        </div>
        <div class="term">
          <div class="label">
            <span v-if="!noAction" style="color: red">*&ensp;</span>
            签字验收人
          </div>
          <div v-if="!noAction" class="content">
            <a-form-model-item prop="signatory" required>
              甲方指定签字验收人
              <a-input
                v-model="clause.signatory"
                placeholder="请输入"
              />
            </a-form-model-item>
          </div>
          <div v-else class="content">
            {{ clause.signatory || '/' }}
          </div>
        </div>
      </div>
    </a-form-model>
  </div>
</template>

<script>
export default {
  name: 'RelocationOtherTerms',
  props: {
    provideClause: {
      type: Object,
      default: () => ({
        signatory: undefined,
      }),
    },
    noAction: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    provideClause: {
      handler (v) {
        this.clause = { ...v };
      },
      immediate: true,
    },
  },
  data () {
    return {
      clause: {
        signatory: undefined,
      },
      /* message需要有空格或实体内容 */
      rules: {
        signatory: [{ required: true, message: ' ', trigger: 'blur' }],
      },
      isError: false,
    };
  },
  methods: {
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.relocationTerms.validate(async valid => {
          if (valid) {
            this.isError = false;
            this.$emit('update:provideClause', this.clause);
            resolve(valid);
          } else {
            this.isError = true;
            reject(new Error('fail'));
          }
        });
      });
    },
  },
  created () {},
};
</script>

<style scoped lang="scss">
.gap-12 {
  margin-bottom: 12px;
}

.common {
  background-color: #fff;
  padding: 12px;
  margin-bottom: 6px;

  .title {
    font-weight: bold;

    display: flex;
    align-items: center;

    .ant-alert {
      flex: 1;
      margin-left: 12px;
    }
  }

  .term {
    display: flex;
    align-items: center;
    height: 40px;
    border-bottom: 0.5px solid #f2f4f7;

    &:first-of-type {
      background-color: #f2f4f7;
    }

    .label {
      width: 130px;
      background-color: #f2f4f7;
      text-align: left;
    }

    .content {
      flex: 1;
      padding-left: 0.5em;
      text-align: left;

      input {
        width: 12em;
      }
    }

    > div {
      height: 40px;
      line-height: 40px;
      text-align: right;
      padding: 0 0.5em;
    }
  }
}
</style>

