/*
            解题思路：将数字每四个拆分一次，每次后面加万，亿，万亿，亿亿作为节权位
            然后单独将每四个数按情况转化为汉字，其他情况按下标即可转化，主要考虑为0的情况，
            当零为后面出现时，直接去除，当在两个大于零的数字中间出现时，将多个零合并为一个零
        */
let numChar = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
let numUnit = ['', '十', '百', '千']; // 权位
let numSection = ['', '万', '亿', '万亿', '亿亿']; // 节权位
const formatSection = (num)=>{
  let arr = (num + '').split('').reverse();
  let str = '';
  for (let i = 0; i < arr.length; i++) { // 将0-9转化为零到九
    let char = arr[i] * 1 === 0 ? numChar[0] : numChar[arr[i]] + numUnit[i]; // 当数字为0时不加权位，非零加权位
    str = char + str;
  }
  let s = str.replace(/零+/g, '零').replace(/零+$/, ''); // 将多个零合并为一个零，并剔除尾端的零
  return s;
};
const formatNum = (num, str)=>{ // 将字符串按个数拆分
  let len = Math.ceil(str.length / num);
  let arr = [];
  for (let i = 0; i < len; i++) {
    let reverseStr = str.split('').reverse().join('');
    let s = reverseStr.slice(i * num, i * num + num).split('').reverse().join('');
    arr.unshift(s);
  }
  return arr;
};
const numberTranToCN = (num)=>{
  let arr = formatNum(4, num + ''); // 将数字每四个拆分一次
  let list = [];
  for (let i = 0; i < arr.length; i++) {
    let str = formatSection(arr[i]);
    list.push(str);
  }
  let reverseList = list.reverse();
  for (let j = 0; j < reverseList.length; j++) {
    reverseList[j] += numSection[j];
  }
  return reverseList.reverse().join('');
};

const numberTranToUpperCN = (n) => {
  if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n)) {
    return '数据非法';
  }
  let unit = '千百拾亿千百拾万千百拾元角分';
  let str = '';
  n = n.replace(',', '');
  n += '00';
  const p = n.indexOf('.');
  if (p >= 0) {
    n = n.substring(0, p) + n.substr(p + 1, 2);
  }
  unit = unit.substr(unit.length - n.length);
  for (let i = 0; i < n.length; i++) {
    str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i);
  }
  return str
    .replace(/零(千|百|拾|角)/g, '零')
    .replace(/(零)+/g, '零')
    .replace(/零(万|亿|元)/g, '$1')
    .replace(/(亿)万|壹(拾)/g, '$1$2')
    .replace(/^元零?|零分/g, '')
    .replace(/元$/g, '元整');
};

export { numberTranToCN, numberTranToUpperCN };

