<template>
  <div class="common">
    <div class="title gap-12">
      其他条款
      <span v-if="!noAction" style="font-size: 12px; color: gray">
        <a-icon type="info-circle" style="margin-left: 4px" />
        具体条款信息可点击下方合同预览按钮进行查看。
      </span>
      <a-alert v-if="isError" message="请完善必填条款" banner closable @close="showWarning = false" />
    </div>
    <a-form-model ref="technologyTerms" :model="clause" :rules="rules">
      <div>
        <div class="term">
          <div class="label">条款标题</div>
          <div>条款内容</div>
        </div>
        <div class="term">
          <div class="label">
            <span style="color: red">*&ensp;</span>
            乙方权利与义务
          </div>
          <div v-if="!noAction" class="content">
            平均开机率&ensp;
            <a-form-model-item prop="averageStartupRate" required>
              <a-input-number
                v-model="clause.averageStartupRate"
                :min="0"
                :step="0.01"
                :precision="2"
                placeholder="请输入"
              />
            </a-form-model-item>
            &ensp;%&emsp;&emsp; 每年停机不超过&ensp;
            <a-form-model-item prop="yearDownMostWorkDay" required>
              <a-input-number
                v-model="clause.yearDownMostWorkDay"
                :min="0"
                :step="1"
                :precision="0"
                placeholder="请输入"
              />
            </a-form-model-item>
            &ensp;个工作日，（一年
            <a-form-model-item prop="yearWorkDay" required>
              <a-input-number
                v-model="clause.yearWorkDay"
                :min="0"
                :step="1"
                :precision="0"
                placeholder="请输入"
              />
            </a-form-model-item>个工作日）
          </div>
          <div v-else class="content">
            平均开机率{{ clause.averageStartupRate || '/' }}%&emsp;&emsp; 每年停机不超过{{
              clause.yearDownMostWorkDay || '/'
            }}个工作日（一年{{ clause.yearWorkDay || '/' }}工作日）
          </div>
        </div>
        <div class="term">
          <div class="label">
            <span style="color: red">*&ensp;</span>
            响应时间
          </div>
          <div v-if="!noAction" class="content">
            <a-form-model-item prop="phoneResponseHour" required>
              <a-input-number
                v-model="clause.phoneResponseHour"
                :min="0"
                :step="0.01"
                :precision="2"
                placeholder="请输入"
              />
            </a-form-model-item>
            &ensp;小时内，电话响应；
            <a-form-model-item prop="sceneResponseHour" required>
              <a-input-number
                v-model="clause.sceneResponseHour"
                :min="0"
                :step="0.01"
                :precision="2"
                placeholder="请输入"
              />
            </a-form-model-item>
            &ensp;小时内，现场响应；
            &ensp;正常工作时间以外
            <a-form-model-item prop="notWorkDaySceneResponseHour" required>
              <a-input-number
                v-model="clause.notWorkDaySceneResponseHour"
                :min="0"
                :step="0.01"
                :precision="2"
                placeholder="请输入"
              />
            </a-form-model-item>
            &ensp;小时内，现场响应
          </div>
          <div v-else class="content">
            {{ clause.phoneResponseHour || '/' }}小时内，电话响应；{{
              clause.sceneResponseHour || '/'
            }}小时内，现场响应；正常工作时间以外，{{ clause.notWorkDaySceneResponseHour || '/' }}小时内，现场响应
          </div>
        </div>
        <div class="term">
          <div class="label">
            <span style="color: red">*&ensp;</span>
            每年对生命支持类的设备提供
          </div>
          <div v-if="!noAction" class="content">
            <a-form-model-item prop="yearMaintenanceTimes" required>
              <a-input-number
                v-model="clause.yearMaintenanceTimes"
                :min="0"
                :step="1"
                :precision="0"
                placeholder="请输入"
              />
            </a-form-model-item>
            &ensp;次保养
          </div>
          <div v-else class="content">{{ clause.yearMaintenanceTimes || '/' }}次保养</div>
        </div>
        <div class="term">
          <div class="label">
            <span style="color: red">*&ensp;</span>
            驻场人员
          </div>
          <div v-if="!noAction" class="content">
            <a-form-model-item prop="onSitePersonNum" required>
              <a-input-number v-model="clause.onSitePersonNum" :min="0" :step="1" :precision="0" placeholder="请输入" />
            </a-form-model-item>
            &ensp;名
          </div>
          <div v-else class="content">{{ clause.onSitePersonNum || '/' }}名</div>
        </div>
        <div class="term">
          <div class="label">
            <span style="color: red">*&ensp;</span>
            提供工作报告名称
          </div>
          <div v-if="!noAction" class="content">
            <a-form-model-item prop="workReport" required>
              <a-input v-model="clause.workReport" placeholder="请输入" />
            </a-form-model-item>
            &ensp;工作报告
          </div>
          <div v-else class="content">{{ clause.workReport || '/' }}工作报告</div>
        </div>
        <div class="term">
          <div class="label">
            <span style="color: red">*&ensp;</span>
            资产总值和服务费率
          </div>
          <div v-if="!noAction" class="content">
            设备原值&ensp;
            <a-form-model-item prop="assetAmount" required>
              <a-input-number v-model="clause.assetAmount" :min="0" :step="0.01" :precision="2" placeholder="请输入" />
            </a-form-model-item>
            &ensp;亿元&emsp;&emsp; 服务费率&ensp;
            <a-form-model-item prop="serviceRate" required>
              <a-input-number v-model="clause.serviceRate" :min="0" :step="0.01" :precision="2" placeholder="请输入" />
            </a-form-model-item>
            &ensp;%
          </div>
          <div v-else class="content">
            设备原值{{ clause.assetAmount || '/' }}亿元&emsp;&emsp; 服务费率{{ clause.serviceRate || '/' }}%
          </div>
        </div>
      </div>
    </a-form-model>
  </div>
</template>

<script>
export default {
  name: 'HospitalAssetManagementTerms',
  props: {
    provideClause: {
      type: Object,
      default: () => ({
        averageStartupRate: undefined,
        yearDownMostWorkDay: undefined,
        yearWorkDay: undefined,
        phoneResponseHour: undefined,
        sceneResponseHour: undefined,
        notWorkDaySceneResponseHour: undefined,
        yearMaintenanceTimes: undefined,
        onSitePersonNum: undefined,
        workReport: undefined,
        assetAmount: undefined,
        serviceRate: undefined,
      }),
    },
    noAction: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    provideClause: {
      handler (v) {
        this.clause = { ...v };
      },
      immediate: true,
    },
  },
  data () {
    return {
      clause: {
        averageStartupRate: undefined,
        yearDownMostWorkDay: undefined,
        yearWorkDay: undefined,
        phoneResponseHour: undefined,
        sceneResponseHour: undefined,
        notWorkDaySceneResponseHour: undefined,
        yearMaintenanceTimes: undefined,
        onSitePersonNum: undefined,
        workReport: undefined,
        assetAmount: undefined,
        serviceRate: undefined,
      },
      /* message需要有空格或实体内容 */
      rules: {
        averageStartupRate: [{ required: true, message: ' ', trigger: 'blur' }],
        yearDownMostWorkDay: [{ required: true, message: ' ', trigger: 'blur' }],
        yearWorkDay: [{ required: true, message: ' ', trigger: 'blur' }],
        phoneResponseHour: [{ required: true, message: ' ', trigger: 'blur' }],
        sceneResponseHour: [{ required: true, message: ' ', trigger: 'blur' }],
        notWorkDaySceneResponseHour: [{ required: true, message: ' ', trigger: 'blur' }],
        yearMaintenanceTimes: [{ required: true, message: ' ', trigger: 'blur' }],
        onSitePersonNum: [{ required: true, message: ' ', trigger: 'blur' }],
        workReport: [{ required: true, message: ' ', trigger: 'blur' }],
        assetAmount: [{ required: true, message: ' ', trigger: 'blur' }],
        serviceRate: [{ required: true, message: ' ', trigger: 'blur' }],
      },
      isError: false,
    };
  },
  methods: {
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.technologyTerms.validate(async valid => {
          if (valid) {
            this.isError = false;
            this.$emit('update:provideClause', this.clause);
            resolve(valid);
          } else {
            this.isError = true;
            reject(new Error('fail'));
          }
        });
      });
    },
  },
  created () {},
};
</script>

<style scoped lang="scss">
.gap-12 {
  margin-bottom: 12px;
}

.common {
  background-color: #fff;
  padding: 12px;
  margin-bottom: 6px;

  .title {
    font-weight: bold;

    display: flex;
    align-items: center;

    .ant-alert {
      flex: 1;
      margin-left: 12px;
    }
  }

  .term {
    display: flex;
    align-items: center;
    height: 40px;
    border-bottom: 0.5px solid #f2f4f7;

    &:first-of-type {
      background-color: #f2f4f7;
    }

    .label {
      width: 220px;
      background-color: #f2f4f7;
      text-align: left;
    }

    .content {
      flex: 1;
      padding-left: 0.5em;
      text-align: left;
      display: flex;
    }

    > div {
      height: 40px;
      line-height: 40px;
      text-align: right;
      padding: 0 0.5em;
    }
  }
}

/deep/ .ant-form-explain {
  display: none;
}
</style>

