<template>
  <div class="common">
    <div class="title gap-12">其他条款<span v-if="!noAction" style="font-size: 12px; color: gray;"><a-icon type="info-circle" style="margin-left: 3px; margin-right: 3px;" />具体条款信息可点击下方合同预览按钮进行查看。</span></div>
    <div>
      <div class="term">
        <div class="label">条款标题</div>
        <div>条款内容</div>
      </div>
      <div class="term">
        <div class="label">开机率</div>
        <div v-if="!noAction" class="content">
          平均开机率&ensp;<a-input-number v-model="clause.averageStartupRate" :min="0" :step="0.01" :precision="2"
            placeholder="请输入" />%&emsp;
          每年停机不超过&ensp;<a-input-number v-model="clause.yearDownMostWorkDay" :min="0" :step="1" :precision="0"
            placeholder="请输入" />个工作日
        </div>
        <div v-else class="content">
          平均开机率{{ clause.averageStartupRate || '/' }}%&emsp;
          每年停机不超过{{ clause.yearDownMostWorkDay || '/' }}个工作日
        </div>
      </div>
      <div class="term">
        <div class="label">响应时间</div>
        <div v-if="!noAction" class="content"><a-input-number v-model="clause.phoneResponseHour" :min="0" :step="0.01"
            :precision="2" placeholder="请输入" />&ensp;小时内，电话响应；<a-input-number v-model="clause.sceneResponseHour" :min="0"
            :step="0.01" :precision="2" placeholder="请输入" />&ensp;小时内，现场响应；</div>
        <div v-else class="content">
          {{clause.phoneResponseHour || '/'}}小时内，电话响应；{{clause.sceneResponseHour || '/'}}小时内，现场响应；</div>
      </div>
      <div class="term">
        <div class="label">每年提供维保次数</div>
        <div v-if="!noAction" class="content"><a-input-number v-model="clause.yearMaintenanceTimes" :min="0" :step="1" :precision="0"
            placeholder="请输入" />&ensp;次</div>
            <div v-else class="content">{{clause.yearMaintenanceTimes || '/'}}次</div>
      </div>
      <div class="term">
        <div class="label">不包含服务</div>
        <div class="content other">
          <a-input v-if="!noAction" v-model="clause.excludeServiceOther" placeholder="请输入" :maxLength="200"></a-input>
          <div v-else class="extra-content" :title="clause.excludeServiceOther">{{ clause.excludeServiceOther || '/' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OtherTerms',
  props: {
    provideClause: {
      type: Object,
      default: () => ({
        averageStartupRate: undefined,
        yearDownMostWorkDay: undefined,
        phoneResponseHour: undefined,
        sceneResponseHour: undefined,
        yearMaintenanceTimes: undefined,
        excludeServiceList: [],
        excludeServiceOther: undefined,
      }),
    },
    noAction: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    provideClause: {
      handler (v) {
        this.clause = { ...v };
      },
      immediate: true,
    },
  },
  data () {
    return {
      clause: {
        averageStartupRate: undefined,
        yearDownMostWorkDay: undefined,
        phoneResponseHour: undefined,
        sceneResponseHour: undefined,
        yearMaintenanceTimes: undefined,
        excludeServiceList: [],
        excludeServiceOther: undefined,
      },
    };
  },
  methods: {
    validate () {
      return new Promise((resolve) => {
        this.$emit('update:provideClause', this.clause);
        resolve();
      });
    },
  },
  created () { },
};
</script>

<style scoped lang="scss">
.gap-12 {
  margin-bottom: 12px;
}

.common {
  background-color: #fff;
  padding: 12px;
  margin-bottom: 6px;

  .title {
    font-weight: bold;
  }

  .term {
    display: flex;
    align-items: center;
    height: 40px;
    border-bottom: 0.5px solid #F2F4F7;

    &:first-of-type {
      background-color: #F2F4F7;
    }

    .label {
      width: 130px;
      background-color: #F2F4F7;
      text-align: left;
    }

    .content {
      flex: 1;
      padding-left: 0.5em;
      text-align: left;

      &.other {
        display: flex;
        align-items: center;

        input {
          flex: 1;
        }

         .extra-content {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    >div {
      height: 40px;
      line-height: 40px;
      text-align: right;
      padding: 0 0.5em;
    }
  }
}
</style>

